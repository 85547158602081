
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">Coverages</h5>
      <div class="row mb-3 gy-2">
        <div class="col-sm-12 col-md-6 d-flex">
          <div class="input-group w-auto me-2">
            <b-form-select style="max-width:100px;" class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
            <b-button variant="primary" class="" @click.prevent="popupModalShow = true" type="button">
            <i class="ri ri-add-fill align-bottom"></i> <span class=""> Create </span></b-button>
          </div>
          <div class="dropdown no-arrow">
            <a class="btn btn-light bg-white dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="ri ri-filter-3-fill"></i>
            </a>
            <ul class="dropdown-menu">
              <li>
                <button @click="selectAllRows()" class="dropdown-item" type="button">
                  {{isSelectAll? 'Invert selection' : 'Select all' }}
                </button>
              </li>
              <li>
                <button @click="confirmDelete()" class="dropdown-item" type="button">Delete selected</button>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-sm-12 col-md-6">
          <div class="input-group mb-0">
            <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
            <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
          </div>
        </div>
      </div>
      <div class="my-3">
				<p class="mb-0">Showing {{paginationInfo}} results</p>
			</div>
      <div class="card">
        <div class="card-body">
          <div class="table-responsive mb-0 loading-viewport">
            <is-loading v-if="isLoading" :box="true" />
            <b-table striped hover ref="selectableTable" :items="pageData.data"
              :fields="columns" responsive="sm">
                <template v-slot:cell(check)="data">
                  <div class="form-check text-center m-auto">
                    <input v-model="selected" type="checkbox" :value="data.item.id" class="form-check-input" :id="`tableSRadio${data.item.id}`" />
                    <label class="form-check-label" :for="`tableSRadio${data.item.id}`"></label>
                  </div>
                </template>
                <template v-slot:cell(name)="data">
                  <div style="min-width:18rem;" v-b-tooltip.hover
                     :title="getDevices(data.item)">
                    <p class="mb-1">{{data.item.name}}</p>
                    <p class="mb-0"><strong>Item:</strong> {{getCategories(data.item)}}</p>
                  </div>
                </template>
                <template v-slot:cell(price)="data">
                  <span> ${{data.item.price}} </span>
                </template>
                <template v-slot:cell(deductible)="data">
                  <span> ${{data.item.deductible}} </span>
                </template>
                <template v-slot:cell(is_featured)="data">
                  <span><span :class="data.item.is_featured ? 'text-success': 'text-dark'">●</span> </span>
                </template>
                <template v-slot:cell(action)="data">
                  <a @click="initEdit(data.item)" href="javascript:void(0);" class="">
                    <i class="ri ri-edit-box-line"></i></a>
                </template>
            </b-table>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <b-pagination v-model="currentPage" :limit="3" class="pagination-rounded"
          :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
      </div>
      <b-modal v-model="popupModalShow" hide-footer centered
        :title="editMode? 'Update coverage':'Add coverage'"
        title-class="fs-6" @hide="initializeAll()">
        <coverage-form v-if="popupModalShow" @closeMe="initializeAll()"
        :editMode="editMode" :editItem="editItem" />
      </b-modal>
    </div>
  </div>
</template>

<script>
import CoverageForm from "@/components/forms/CoverageForm.vue"
import IsLoading from '@/components/IsLoading.vue'
import _ from 'lodash';

export default {
  name: "coverage-list",
  components:{
    CoverageForm,
    IsLoading
  },
  // compatConfig: { MODE: 3 },
  data() {
    return {
      isLoading: false,
      popupModalShow: false,
      editMode: false,
      editItem: null,
      searchQuery: "",
      pageOptions: [10, 25, 50, 100],
      selected: [],
      isSelectAll: false,
      columns: [
          {
              key: "check",
              label: ""
          },
          {
            key: "name",
            label: "Name",
          },
          
          {
            key: "price",
            label: "Price",
          },
          {
            key: "max_coverage",
            label: "Max",
          },
          {
            key: "deductible",
            label: "Deductible",
          },
          {
            key: "plan_interval",
            label: "Interval",
          },
          {
              key: "is_featured",
              label: "Featured",
          },
          "action"
      ]
    }
  },
  computed: {
    pageData(){
      return this.$store.state.coverage.pageData
    },
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchItems(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 25
      },
      set(val) {
        this.fetchItems(1, val)
      }
    },
    getDevices(){
      return (item) => [...item.monthly_devices, ...item.annual_devices].map(i => i.model).join(', ')
    },
    getCategories(){
      return (item) => item.categories.map(i => i.title).join('|')
    },
    paginationInfo(){
			if(!this.currentPage) return '0';
        return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
        - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
        ? this.currentPage * this.itemsPerPage : this.pageData.total}
        of ${this.pageData.total}`
    },
  },
  methods: {
    selectAllRows() {
      this.isSelectAll = !this.isSelectAll
      if(this.isSelectAll){
        this.selected = this.pageData.data.map(o => o.id );
      }else{
        this.selected = []
      }
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null) {
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.isLoading = true
      this.$store.dispatch("coverage/fetchCoverages", payload)
      .then((_) => this.isLoading = false )
    },
    confirmDelete() {
      if(!this.selected.length){ this.alertError("You have not selected any item"); return}
      let text = `You are about to delete ${this.selected.length} coverage${this.selected.length > 1 ? 's':'' }`;
      
      this.confirmDelete({
        text: text,
      }).then((result) => {
        if (result.value) {
          const formData = new FormData()
          formData.append('data', JSON.stringify({items: this.selected}))
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.post('/coverages/delete', formData)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.fetchCoverages()
            }
          })
        }
      });
    },
    initEdit(item){
      this.editMode = true;
      this.editItem = {...item}
      this.popupModalShow = true  
    },
    initializeAll(){
      this.editMode = false;
      this.editItem = null;
      this.popupModalShow = false;
    },
  },
  created(){
    this.fetchItems()
    this.$store.dispatch("category/fetchItems")
  },
}

</script>

