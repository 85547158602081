<template>
    <form autocomplete="off" class="">
        <div class="row g-3">
            <div class="col-12">
                <label for="inputName">
                    Name <span class="text-danger small">*</span>
                </label>
                <input type="text" v-model="data.name"
                    :class="{ 'is-invalid': v$.data.name.$error }"
                    id="inputName" name="name" class="form-control"
                    placeholder="Coverage name" />
                <div v-for="(item, index) in v$.data.name.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-12">
                <label for="inputDisplayName">
                    Display name (optional)
                </label>
                <input type="text" v-model="data.display_name"
                    id="inputDisplayName" name="display_name" class="form-control"
                    placeholder="Display name" />
            </div>
            <div class="col-md-6">
                <label for="inputPrice">
                    Price <span class="text-danger">*</span>
                </label>
                <input type="number" v-model="data.price" class="form-control"
                name="price" id="inputPrice" :class="{ 'is-invalid': v$.data.price.$error }" placeholder="Enter Price" />
                <div v-for="(item, index) in v$.data.price.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <label for="inputDeductible">
                    Deductable <span class="text-danger">*</span>
                </label>
                <input type="number" v-model="data.deductible" class="form-control"
                name="deductible" id="inputDeductible" :class="{ 'is-invalid': v$.data.deductible.$error }" placeholder="Enter deductible" />
                <div v-for="(item, index) in v$.data.deductible.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <label class="form-label">Plan Interval</label>
                <select class="form-select" v-model="data.plan_interval"
                    name="plan_interval" placeholder="--select plan interval--" 
                    :class="{ 'is-invalid': v$.data.plan_interval.$error}">
                    <option disabled value="">--select plan interval--</option>
                    <option value="month">Monthly</option>
                    <option value="year">Yearly</option>
                </select>
                <div v-for="(item, index) in v$.data.plan_interval.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <label for="inputMaxCoverage">
                    Max Coverage <span class="text-danger">*</span>
                </label>
                <input type="number" v-model="data.max_coverage" class="form-control"
                name="max_coverage" id="inputMaxCoverage" :class="{ 'is-invalid': v$.data.max_coverage.$error }" placeholder="Enter max-coverage" />
                <div v-for="(item, index) in v$.data.max_coverage.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-12">
                <label>Items covered</label>
                <multiselect 
                mode="tags"
                :close-on-select="false"
                searchable
                valueProp="id"
                trackBy="id" label="title"
                :class="{ 'is-invalid': v$.data.categories.$error }"
                :options="categories" 
                v-model="data.categories" placeholder="--select--"></multiselect>
                <div v-for="(item, index) in v$.data.categories.$errors" :key="index"
                    class="text-danger">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-6 col-md-4">
                <b-form-checkbox v-model="data.is_featured" name="is_featured" switch>
                    Featured
                </b-form-checkbox>
            </div>
            <div class="col-12">
                <div class="d-flex mt-3 justify-content-end">
                    <b-button variant="primary" 
                    @click.prevent="editMode? update() : create()"
                     type="button">{{editMode? 'Update':'Submit'}}</b-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import Multiselect from '@vueform/multiselect'

const validateList = (items) => items.length > 0

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components:{
        Multiselect,
    },
    props:{
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            data: {
                name: "",
                display_name: "",
                price: null,
                currency_id: null,
                deductible: 1,
                is_featured: true,
                max_coverage: null,
                plan_interval: "month",
                categories:[]
            },
        }
    },
    validations() {
        return{
            data:{
                name: {
                    required: helpers.withMessage("Name is required", required),
                },
                deductible: {
                    required: helpers.withMessage("Deductible is required", required),
                },
                price: {
                    required: helpers.withMessage("Price is required", required),
                },
                plan_interval: {
                    required: helpers.withMessage("Plan interval is required", required),
                },
                categories: {
                    validateList: helpers.withMessage("Item covered is required", validateList),
                },
                max_coverage: {
                    required: helpers.withMessage("Max coverage is required", required),
                },
            }
        }
    },
    computed:{
        categories(){
            return this.$store.state.category.list
        }
    },
    methods:{
        create() {
            this.v$.$validate().then(result =>{
                if (!result) { this.alertError(this.v$.$errors[0].$message); return };
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/coverages/create`, formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$store.commit("coverage/ADD_COVERAGE", response.data.data)
                        this.resetForm();
                    }
                })
            })
        },
        update(){
             this.v$.$validate().then(result =>{
                if (!result) { this.alertError(this.v$.$errors[0].$message); return };
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/coverages/${this.editItem.id}/update`,formData)
                    .then((response) => {
                        this.$store.dispatch("changeLoaderValue", false)
                        if(response.data.success){
                            this.$store.commit("coverage/UPDATE_COVERAGE", response.data.data)
                            this.resetForm()
                        }
                    })
            
            })
        },
        resetForm(){
            this.v$.$reset()
            this.$emit("closeMe")
        }
    },
    mounted(){
        if(this.editMode){
            this.data = this.$filters.patchUpdatables(this.data, this.editItem)
            this.data.categories = this.data.categories.map(i => i.id);
        }
    }
}
</script>